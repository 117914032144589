import { Box, Label, Typography } from '@branch-messenger/willow-ui'

import {
  ContentCard,
  ContentCardBackButton,
  ContentCardBody,
  ContentCardButtonLink,
  ContentCardFooter,
  ContentCardHeading,
} from '@/components/contentCard'

import { StyledConfirmOrderDetails } from './styles'

export const ConfirmOrder = () => {
  return (
    <ContentCard>
      <ContentCardBackButton backRoute="/cardInfo" />
      <ContentCardBody>
        <ContentCardHeading subtitle="Please take a moment to review your order details.">
          Verify your debit card order details.
        </ContentCardHeading>
        <StyledConfirmOrderDetails $gap={4}>
          <StyledConfirmOrderDetails $gap={2}>
            <Label $uppercase $color="textMuted">
              Name on card
            </Label>
            <Typography $bold>Legalfirstname LegalLastname</Typography>
          </StyledConfirmOrderDetails>
          <StyledConfirmOrderDetails $gap={2}>
            <Label $uppercase $color="textMuted">
              Ship card to
            </Label>
            <Box $display="flex" $direction="column" $align="start">
              <Typography $bold>106 Bush Street</Typography>
              <Typography $bold>Apt 101</Typography>
              <Typography $bold>Minneapolis, MN 55431</Typography>
            </Box>
          </StyledConfirmOrderDetails>
        </StyledConfirmOrderDetails>
      </ContentCardBody>
      <ContentCardFooter>
        <ContentCardButtonLink to="/orderConfirmed">
          Continue
        </ContentCardButtonLink>
      </ContentCardFooter>
    </ContentCard>
  )
}
