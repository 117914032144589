import { ButtonLink } from '@branch-messenger/willow-ui'
import {
  createLink,
  LinkComponent,
  NavigateOptions,
} from '@tanstack/react-router'

import { useGetPersistedSearch } from '@/hooks/useGetPersistedSearch'

export const CustomButtonLink = createLink(ButtonLink)

export const ContentCardButtonLink: LinkComponent<typeof ButtonLink> = ({
  search,
  ...props
}) => {
  const getPersistedSearch = useGetPersistedSearch()
  return (
    <CustomButtonLink
      size="lg"
      search={
        getPersistedSearch(search as NavigateOptions['search']) as typeof search
      }
      {...props}
    />
  )
}
