import { QueryCache } from '@tanstack/react-query'

import { useToastStore } from '@/store/toast'

import { sendAnalyticsEvent } from './analytics'
import { handleHttpException } from './httpExceptionHandler'

export const queryCache = new QueryCache({
  onError: (error, query) => {
    const { meta = {} } = query
    const {
      errorToastData,
      useDescriptionFromError,
      showToastForNonHttpErrors,
      errorAnalyticsData,
    } = meta

    if (errorAnalyticsData) {
      const { domain, event, properties } = errorAnalyticsData
      sendAnalyticsEvent(domain, event, properties)
    }

    if (useDescriptionFromError && errorToastData) {
      handleHttpException(error, {
        onHttpError: ({ response }) => {
          useToastStore.getState().addToast({
            description: response?.data?.meta?.errorDetail,
            ...errorToastData,
          })
        },
        onOtherError: () => {
          showToastForNonHttpErrors &&
            useToastStore.getState().addToast(errorToastData)
        },
      })
    } else if (errorToastData) {
      useToastStore.getState().addToast(errorToastData)
    }
  },
})
