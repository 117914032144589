import { queryOptions } from '@tanstack/react-query'

import {
  getWorkerInfo,
  getWorkerOnboardingState,
  getWorkerOTPInfo,
  GetWorkerPayload,
} from '@/api/workers'

export const WORKER_OTP_INFO_QUERY_KEY = 'WORKER_OTP_INFO_QUERY_KEY'
export const getWorkerOtpInfoQueryOptions = (payload: GetWorkerPayload) =>
  queryOptions({
    queryKey: [WORKER_OTP_INFO_QUERY_KEY, payload],
    queryFn: async () => {
      const response = await getWorkerOTPInfo(payload)
      return response.data
    },
    staleTime: Infinity,
    gcTime: Infinity,
  })

export const WORKER_INFO_QUERY_KEY = 'WORKER_INFO_QUERY_KEY'
export const workerInfoQueryOptions = queryOptions({
  queryKey: [WORKER_INFO_QUERY_KEY],
  queryFn: async () => {
    const response = await getWorkerInfo()
    return response.data
  },
  staleTime: Infinity,
  gcTime: Infinity,
})

export const WORKER_ONBOARDING_STATE_QUERY_KEY =
  'WORKER_ONBOARDING_STATE_QUERY_KEY'
export const workerOnboardingStateQueryOptions = queryOptions({
  queryKey: [WORKER_ONBOARDING_STATE_QUERY_KEY],
  queryFn: async () => {
    const response = await getWorkerOnboardingState()
    return response.data
  },
})
