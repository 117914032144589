import { Typography } from '@branch-messenger/willow-ui'
import { BranchLogo } from '@branch-messenger/willow-ui/icons'
import { Outlet } from '@tanstack/react-router'
import { FC } from 'react'

import { StyledAppBody, StyledBrandContainer } from './styles'

export const AppBody: FC = () => {
  return (
    <StyledAppBody>
      <Outlet />
      <StyledBrandContainer $display="flex" $gap={2}>
        <Typography $size="sm" $color="textMuted">
          powered by
        </Typography>
        <BranchLogo />
      </StyledBrandContainer>
    </StyledAppBody>
  )
}
