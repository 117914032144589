import { QueryClient } from '@tanstack/react-query'
import { createRootRouteWithContext } from '@tanstack/react-router'
import { StoreApi } from 'zustand'

import { AppError, AppLayout } from '@/components/appLayout'
import { AppStore } from '@/store/app.types'

import { rootSearchSchema } from '../lib/searchSchemas/rootSearchSchema'

type RouteContext = {
  queryClient: QueryClient
  appStore: StoreApi<AppStore>
}

export const Route = createRootRouteWithContext<RouteContext>()({
  validateSearch: rootSearchSchema,
  component: AppLayout,
  errorComponent: AppError,
})
