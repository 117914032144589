import { createRouter } from '@tanstack/react-router'

import { ContentCardError, ContentCardLoading } from '@/components/contentCard'
import { queryClient } from '@/lib/queryClient'
import { appStore } from '@/store/app'
import { EventProperties } from '@/types/analytics'

import { routeTree } from '../routeTree.gen'
import { sendAnalyticsEvent } from './analytics'

export const router = createRouter({
  context: {
    queryClient,
    appStore,
  },
  routeTree,
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  defaultPendingComponent: ContentCardLoading,
  defaultErrorComponent: ContentCardError,
})

router.subscribe('onLoad', event => {
  const currentMatch = router.state.matches[router.state.matches.length - 1]
  const location = event.toLocation
  if (currentMatch.params) {
    const pathParams = Object.values(currentMatch.params)
    const locationParts = location.pathname.split('/')
    const strippedPath = locationParts
      .filter(part => !pathParams.includes(part))
      .join('/')
    return sendAnalyticsEvent(
      'PAGEVIEW',
      strippedPath,
      currentMatch.params as EventProperties
    )
  }
  sendAnalyticsEvent('PAGEVIEW', location.pathname)
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
