import { createFileRoute, redirect } from '@tanstack/react-router'

import { Organization } from '@/api/organizations'
import { getExternalOrganization } from '@/api/organizations'
import { initializeOnboardingSession } from '@/api/workers'
import { RouteError } from '@/errors/RouteError'
import { getTranslatedWorkerOTPInfo } from '@/lib/getTranslatedWorkerOTPInfo'
import { ORG_QUERY_KEY, orgQueryOptions } from '@/queryOptions/organizations'
import { workerOnboardingStateQueryOptions } from '@/queryOptions/workers'

export const Route = createFileRoute('/')({
  loaderDeps: ({ search }) => search,
  loader: async ({
    deps: { external_org_id, org_id, type, worker_id, external_worker_id },
    context: { queryClient, appStore },
  }) => {
    if (!worker_id && !external_worker_id) {
      throw new RouteError(
        'worker_id missing. Please make sure you have the correct link.'
      )
    }

    let searchParams = {
      external_org_id,
      org_id,
      type,
      worker_id,
      external_worker_id,
    }
    let orgData: Organization | undefined
    if (org_id) {
      orgData = await queryClient.ensureQueryData(orgQueryOptions(org_id))
    } else if (external_org_id) {
      const { data } = await getExternalOrganization({ external_org_id, type })
      queryClient.setQueryData([ORG_QUERY_KEY, data.id], data)
      searchParams = { ...searchParams, org_id: data.id }
      orgData = data
    }
    if (!orgData) {
      throw new RouteError(
        'Organization details not found. Please make sure you have the correct link.'
      )
    }

    appStore.getState().setAppInitialized(true)
    await initializeOnboardingSession({ org_id: orgData.id })
    const onboardingState = await queryClient.ensureQueryData(
      workerOnboardingStateQueryOptions
    )

    if (onboardingState.cur_step === 'ORG_PASSCODE') {
      redirect({ to: '/orgPasscode', search: searchParams, throw: true })
    }

    const { translatedSearchParams, workerOtpInfo } =
      await getTranslatedWorkerOTPInfo(searchParams, queryClient)

    if (!workerOtpInfo?.email && !workerOtpInfo?.phone) {
      redirect({
        to: '/missingAccountInfo',
        search: translatedSearchParams,
        throw: true,
      })
    }

    //TODO: Add logic for different flow steps

    redirect({ to: '/terms', search: translatedSearchParams, throw: true })
  },
})
