import * as Sentry from '@sentry/react'
import axios, { AxiosError } from 'axios'

import { ErrorResponse } from '@/api/types'

export type ExceptionOptionProps<T> = {
  onHttpError: (err: AxiosError<T>) => void
  onOtherError?: (err: unknown) => void
}

/** Pass an exception error and use the callbacks to handle different error types */
export const handleHttpException = <T = ErrorResponse>(
  error: unknown,
  options: ExceptionOptionProps<T>
) => {
  if (axios.isAxiosError(error)) {
    options.onHttpError(error as AxiosError<T>)
  } else {
    Sentry.captureException(error)
    options.onOtherError && options.onOtherError(error)
  }
}
