/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as InitializedImport } from './routes/_initialized'
import { Route as SplatImport } from './routes/$'
import { Route as IndexImport } from './routes/index'
import { Route as InitializedVerifyOTPImport } from './routes/_initialized/verifyOTP'
import { Route as InitializedVerificationSelectionImport } from './routes/_initialized/verificationSelection'
import { Route as InitializedTermsImport } from './routes/_initialized/terms'
import { Route as InitializedPhoneVerificationImport } from './routes/_initialized/phoneVerification'
import { Route as InitializedConfirmOrderImport } from './routes/_initialized/confirmOrder'
import { Route as InitializedCardInfoImport } from './routes/_initialized/cardInfo'
import { Route as InitializedTermsAgreementsStepImport } from './routes/_initialized/terms/agreements.$step'

// Create Virtual Routes

const InitializedVerifyAccountInfoLazyImport = createFileRoute(
  '/_initialized/verifyAccountInfo',
)()
const InitializedPasscodeLazyImport = createFileRoute(
  '/_initialized/passcode',
)()
const InitializedOrgPasscodeLazyImport = createFileRoute(
  '/_initialized/orgPasscode',
)()
const InitializedOrderConfirmedLazyImport = createFileRoute(
  '/_initialized/orderConfirmed',
)()
const InitializedMissingAccountInfoLazyImport = createFileRoute(
  '/_initialized/missingAccountInfo',
)()
const InitializedLinkedAccountSignInLazyImport = createFileRoute(
  '/_initialized/linkedAccountSignIn',
)()
const InitializedLinkAccountSuccessLazyImport = createFileRoute(
  '/_initialized/linkAccountSuccess',
)()
const InitializedLinkAccountLazyImport = createFileRoute(
  '/_initialized/linkAccount',
)()
const InitializedDownloadAppLazyImport = createFileRoute(
  '/_initialized/downloadApp',
)()
const InitializedDirectDepositAuthorizationLazyImport = createFileRoute(
  '/_initialized/directDepositAuthorization',
)()
const InitializedDateOfBirthLazyImport = createFileRoute(
  '/_initialized/dateOfBirth',
)()
const InitializedConfirmPasscodeLazyImport = createFileRoute(
  '/_initialized/confirmPasscode',
)()
const InitializedTermsIndexLazyImport = createFileRoute(
  '/_initialized/terms/',
)()
const InitializedAddressVerificationResultsLazyImport = createFileRoute(
  '/_initialized/address/verificationResults',
)()
const InitializedAddressPoBoxErrorLazyImport = createFileRoute(
  '/_initialized/address/poBoxError',
)()
const InitializedAddressManualAddressFormLazyImport = createFileRoute(
  '/_initialized/address/manualAddressForm',
)()
const InitializedAddressAddressLookupLazyImport = createFileRoute(
  '/_initialized/address/addressLookup',
)()

// Create/Update Routes

const InitializedRoute = InitializedImport.update({
  id: '/_initialized',
  getParentRoute: () => rootRoute,
} as any)

const SplatRoute = SplatImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const InitializedVerifyAccountInfoLazyRoute =
  InitializedVerifyAccountInfoLazyImport.update({
    id: '/verifyAccountInfo',
    path: '/verifyAccountInfo',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/verifyAccountInfo.lazy').then((d) => d.Route),
  )

const InitializedPasscodeLazyRoute = InitializedPasscodeLazyImport.update({
  id: '/passcode',
  path: '/passcode',
  getParentRoute: () => InitializedRoute,
} as any).lazy(() =>
  import('./routes/_initialized/passcode.lazy').then((d) => d.Route),
)

const InitializedOrgPasscodeLazyRoute = InitializedOrgPasscodeLazyImport.update(
  {
    id: '/orgPasscode',
    path: '/orgPasscode',
    getParentRoute: () => InitializedRoute,
  } as any,
).lazy(() =>
  import('./routes/_initialized/orgPasscode.lazy').then((d) => d.Route),
)

const InitializedOrderConfirmedLazyRoute =
  InitializedOrderConfirmedLazyImport.update({
    id: '/orderConfirmed',
    path: '/orderConfirmed',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/orderConfirmed.lazy').then((d) => d.Route),
  )

const InitializedMissingAccountInfoLazyRoute =
  InitializedMissingAccountInfoLazyImport.update({
    id: '/missingAccountInfo',
    path: '/missingAccountInfo',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/missingAccountInfo.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedLinkedAccountSignInLazyRoute =
  InitializedLinkedAccountSignInLazyImport.update({
    id: '/linkedAccountSignIn',
    path: '/linkedAccountSignIn',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/linkedAccountSignIn.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedLinkAccountSuccessLazyRoute =
  InitializedLinkAccountSuccessLazyImport.update({
    id: '/linkAccountSuccess',
    path: '/linkAccountSuccess',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/linkAccountSuccess.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedLinkAccountLazyRoute = InitializedLinkAccountLazyImport.update(
  {
    id: '/linkAccount',
    path: '/linkAccount',
    getParentRoute: () => InitializedRoute,
  } as any,
).lazy(() =>
  import('./routes/_initialized/linkAccount.lazy').then((d) => d.Route),
)

const InitializedDownloadAppLazyRoute = InitializedDownloadAppLazyImport.update(
  {
    id: '/downloadApp',
    path: '/downloadApp',
    getParentRoute: () => InitializedRoute,
  } as any,
).lazy(() =>
  import('./routes/_initialized/downloadApp.lazy').then((d) => d.Route),
)

const InitializedDirectDepositAuthorizationLazyRoute =
  InitializedDirectDepositAuthorizationLazyImport.update({
    id: '/directDepositAuthorization',
    path: '/directDepositAuthorization',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/directDepositAuthorization.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedDateOfBirthLazyRoute = InitializedDateOfBirthLazyImport.update(
  {
    id: '/dateOfBirth',
    path: '/dateOfBirth',
    getParentRoute: () => InitializedRoute,
  } as any,
).lazy(() =>
  import('./routes/_initialized/dateOfBirth.lazy').then((d) => d.Route),
)

const InitializedConfirmPasscodeLazyRoute =
  InitializedConfirmPasscodeLazyImport.update({
    id: '/confirmPasscode',
    path: '/confirmPasscode',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/confirmPasscode.lazy').then((d) => d.Route),
  )

const InitializedVerifyOTPRoute = InitializedVerifyOTPImport.update({
  id: '/verifyOTP',
  path: '/verifyOTP',
  getParentRoute: () => InitializedRoute,
} as any).lazy(() =>
  import('./routes/_initialized/verifyOTP.lazy').then((d) => d.Route),
)

const InitializedVerificationSelectionRoute =
  InitializedVerificationSelectionImport.update({
    id: '/verificationSelection',
    path: '/verificationSelection',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/verificationSelection.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedTermsRoute = InitializedTermsImport.update({
  id: '/terms',
  path: '/terms',
  getParentRoute: () => InitializedRoute,
} as any)

const InitializedPhoneVerificationRoute =
  InitializedPhoneVerificationImport.update({
    id: '/phoneVerification',
    path: '/phoneVerification',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/phoneVerification.lazy').then((d) => d.Route),
  )

const InitializedConfirmOrderRoute = InitializedConfirmOrderImport.update({
  id: '/confirmOrder',
  path: '/confirmOrder',
  getParentRoute: () => InitializedRoute,
} as any)

const InitializedCardInfoRoute = InitializedCardInfoImport.update({
  id: '/cardInfo',
  path: '/cardInfo',
  getParentRoute: () => InitializedRoute,
} as any)

const InitializedTermsIndexLazyRoute = InitializedTermsIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => InitializedTermsRoute,
} as any).lazy(() =>
  import('./routes/_initialized/terms/index.lazy').then((d) => d.Route),
)

const InitializedAddressVerificationResultsLazyRoute =
  InitializedAddressVerificationResultsLazyImport.update({
    id: '/address/verificationResults',
    path: '/address/verificationResults',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/address/verificationResults.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedAddressPoBoxErrorLazyRoute =
  InitializedAddressPoBoxErrorLazyImport.update({
    id: '/address/poBoxError',
    path: '/address/poBoxError',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/address/poBoxError.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedAddressManualAddressFormLazyRoute =
  InitializedAddressManualAddressFormLazyImport.update({
    id: '/address/manualAddressForm',
    path: '/address/manualAddressForm',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/address/manualAddressForm.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedAddressAddressLookupLazyRoute =
  InitializedAddressAddressLookupLazyImport.update({
    id: '/address/addressLookup',
    path: '/address/addressLookup',
    getParentRoute: () => InitializedRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/address/addressLookup.lazy').then(
      (d) => d.Route,
    ),
  )

const InitializedTermsAgreementsStepRoute =
  InitializedTermsAgreementsStepImport.update({
    id: '/agreements/$step',
    path: '/agreements/$step',
    getParentRoute: () => InitializedTermsRoute,
  } as any).lazy(() =>
    import('./routes/_initialized/terms/agreements.$step.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/$': {
      id: '/$'
      path: '/$'
      fullPath: '/$'
      preLoaderRoute: typeof SplatImport
      parentRoute: typeof rootRoute
    }
    '/_initialized': {
      id: '/_initialized'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof InitializedImport
      parentRoute: typeof rootRoute
    }
    '/_initialized/cardInfo': {
      id: '/_initialized/cardInfo'
      path: '/cardInfo'
      fullPath: '/cardInfo'
      preLoaderRoute: typeof InitializedCardInfoImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/confirmOrder': {
      id: '/_initialized/confirmOrder'
      path: '/confirmOrder'
      fullPath: '/confirmOrder'
      preLoaderRoute: typeof InitializedConfirmOrderImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/phoneVerification': {
      id: '/_initialized/phoneVerification'
      path: '/phoneVerification'
      fullPath: '/phoneVerification'
      preLoaderRoute: typeof InitializedPhoneVerificationImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/terms': {
      id: '/_initialized/terms'
      path: '/terms'
      fullPath: '/terms'
      preLoaderRoute: typeof InitializedTermsImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/verificationSelection': {
      id: '/_initialized/verificationSelection'
      path: '/verificationSelection'
      fullPath: '/verificationSelection'
      preLoaderRoute: typeof InitializedVerificationSelectionImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/verifyOTP': {
      id: '/_initialized/verifyOTP'
      path: '/verifyOTP'
      fullPath: '/verifyOTP'
      preLoaderRoute: typeof InitializedVerifyOTPImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/confirmPasscode': {
      id: '/_initialized/confirmPasscode'
      path: '/confirmPasscode'
      fullPath: '/confirmPasscode'
      preLoaderRoute: typeof InitializedConfirmPasscodeLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/dateOfBirth': {
      id: '/_initialized/dateOfBirth'
      path: '/dateOfBirth'
      fullPath: '/dateOfBirth'
      preLoaderRoute: typeof InitializedDateOfBirthLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/directDepositAuthorization': {
      id: '/_initialized/directDepositAuthorization'
      path: '/directDepositAuthorization'
      fullPath: '/directDepositAuthorization'
      preLoaderRoute: typeof InitializedDirectDepositAuthorizationLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/downloadApp': {
      id: '/_initialized/downloadApp'
      path: '/downloadApp'
      fullPath: '/downloadApp'
      preLoaderRoute: typeof InitializedDownloadAppLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/linkAccount': {
      id: '/_initialized/linkAccount'
      path: '/linkAccount'
      fullPath: '/linkAccount'
      preLoaderRoute: typeof InitializedLinkAccountLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/linkAccountSuccess': {
      id: '/_initialized/linkAccountSuccess'
      path: '/linkAccountSuccess'
      fullPath: '/linkAccountSuccess'
      preLoaderRoute: typeof InitializedLinkAccountSuccessLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/linkedAccountSignIn': {
      id: '/_initialized/linkedAccountSignIn'
      path: '/linkedAccountSignIn'
      fullPath: '/linkedAccountSignIn'
      preLoaderRoute: typeof InitializedLinkedAccountSignInLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/missingAccountInfo': {
      id: '/_initialized/missingAccountInfo'
      path: '/missingAccountInfo'
      fullPath: '/missingAccountInfo'
      preLoaderRoute: typeof InitializedMissingAccountInfoLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/orderConfirmed': {
      id: '/_initialized/orderConfirmed'
      path: '/orderConfirmed'
      fullPath: '/orderConfirmed'
      preLoaderRoute: typeof InitializedOrderConfirmedLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/orgPasscode': {
      id: '/_initialized/orgPasscode'
      path: '/orgPasscode'
      fullPath: '/orgPasscode'
      preLoaderRoute: typeof InitializedOrgPasscodeLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/passcode': {
      id: '/_initialized/passcode'
      path: '/passcode'
      fullPath: '/passcode'
      preLoaderRoute: typeof InitializedPasscodeLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/verifyAccountInfo': {
      id: '/_initialized/verifyAccountInfo'
      path: '/verifyAccountInfo'
      fullPath: '/verifyAccountInfo'
      preLoaderRoute: typeof InitializedVerifyAccountInfoLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/address/addressLookup': {
      id: '/_initialized/address/addressLookup'
      path: '/address/addressLookup'
      fullPath: '/address/addressLookup'
      preLoaderRoute: typeof InitializedAddressAddressLookupLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/address/manualAddressForm': {
      id: '/_initialized/address/manualAddressForm'
      path: '/address/manualAddressForm'
      fullPath: '/address/manualAddressForm'
      preLoaderRoute: typeof InitializedAddressManualAddressFormLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/address/poBoxError': {
      id: '/_initialized/address/poBoxError'
      path: '/address/poBoxError'
      fullPath: '/address/poBoxError'
      preLoaderRoute: typeof InitializedAddressPoBoxErrorLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/address/verificationResults': {
      id: '/_initialized/address/verificationResults'
      path: '/address/verificationResults'
      fullPath: '/address/verificationResults'
      preLoaderRoute: typeof InitializedAddressVerificationResultsLazyImport
      parentRoute: typeof InitializedImport
    }
    '/_initialized/terms/': {
      id: '/_initialized/terms/'
      path: '/'
      fullPath: '/terms/'
      preLoaderRoute: typeof InitializedTermsIndexLazyImport
      parentRoute: typeof InitializedTermsImport
    }
    '/_initialized/terms/agreements/$step': {
      id: '/_initialized/terms/agreements/$step'
      path: '/agreements/$step'
      fullPath: '/terms/agreements/$step'
      preLoaderRoute: typeof InitializedTermsAgreementsStepImport
      parentRoute: typeof InitializedTermsImport
    }
  }
}

// Create and export the route tree

interface InitializedTermsRouteChildren {
  InitializedTermsIndexLazyRoute: typeof InitializedTermsIndexLazyRoute
  InitializedTermsAgreementsStepRoute: typeof InitializedTermsAgreementsStepRoute
}

const InitializedTermsRouteChildren: InitializedTermsRouteChildren = {
  InitializedTermsIndexLazyRoute: InitializedTermsIndexLazyRoute,
  InitializedTermsAgreementsStepRoute: InitializedTermsAgreementsStepRoute,
}

const InitializedTermsRouteWithChildren =
  InitializedTermsRoute._addFileChildren(InitializedTermsRouteChildren)

interface InitializedRouteChildren {
  InitializedCardInfoRoute: typeof InitializedCardInfoRoute
  InitializedConfirmOrderRoute: typeof InitializedConfirmOrderRoute
  InitializedPhoneVerificationRoute: typeof InitializedPhoneVerificationRoute
  InitializedTermsRoute: typeof InitializedTermsRouteWithChildren
  InitializedVerificationSelectionRoute: typeof InitializedVerificationSelectionRoute
  InitializedVerifyOTPRoute: typeof InitializedVerifyOTPRoute
  InitializedConfirmPasscodeLazyRoute: typeof InitializedConfirmPasscodeLazyRoute
  InitializedDateOfBirthLazyRoute: typeof InitializedDateOfBirthLazyRoute
  InitializedDirectDepositAuthorizationLazyRoute: typeof InitializedDirectDepositAuthorizationLazyRoute
  InitializedDownloadAppLazyRoute: typeof InitializedDownloadAppLazyRoute
  InitializedLinkAccountLazyRoute: typeof InitializedLinkAccountLazyRoute
  InitializedLinkAccountSuccessLazyRoute: typeof InitializedLinkAccountSuccessLazyRoute
  InitializedLinkedAccountSignInLazyRoute: typeof InitializedLinkedAccountSignInLazyRoute
  InitializedMissingAccountInfoLazyRoute: typeof InitializedMissingAccountInfoLazyRoute
  InitializedOrderConfirmedLazyRoute: typeof InitializedOrderConfirmedLazyRoute
  InitializedOrgPasscodeLazyRoute: typeof InitializedOrgPasscodeLazyRoute
  InitializedPasscodeLazyRoute: typeof InitializedPasscodeLazyRoute
  InitializedVerifyAccountInfoLazyRoute: typeof InitializedVerifyAccountInfoLazyRoute
  InitializedAddressAddressLookupLazyRoute: typeof InitializedAddressAddressLookupLazyRoute
  InitializedAddressManualAddressFormLazyRoute: typeof InitializedAddressManualAddressFormLazyRoute
  InitializedAddressPoBoxErrorLazyRoute: typeof InitializedAddressPoBoxErrorLazyRoute
  InitializedAddressVerificationResultsLazyRoute: typeof InitializedAddressVerificationResultsLazyRoute
}

const InitializedRouteChildren: InitializedRouteChildren = {
  InitializedCardInfoRoute: InitializedCardInfoRoute,
  InitializedConfirmOrderRoute: InitializedConfirmOrderRoute,
  InitializedPhoneVerificationRoute: InitializedPhoneVerificationRoute,
  InitializedTermsRoute: InitializedTermsRouteWithChildren,
  InitializedVerificationSelectionRoute: InitializedVerificationSelectionRoute,
  InitializedVerifyOTPRoute: InitializedVerifyOTPRoute,
  InitializedConfirmPasscodeLazyRoute: InitializedConfirmPasscodeLazyRoute,
  InitializedDateOfBirthLazyRoute: InitializedDateOfBirthLazyRoute,
  InitializedDirectDepositAuthorizationLazyRoute:
    InitializedDirectDepositAuthorizationLazyRoute,
  InitializedDownloadAppLazyRoute: InitializedDownloadAppLazyRoute,
  InitializedLinkAccountLazyRoute: InitializedLinkAccountLazyRoute,
  InitializedLinkAccountSuccessLazyRoute:
    InitializedLinkAccountSuccessLazyRoute,
  InitializedLinkedAccountSignInLazyRoute:
    InitializedLinkedAccountSignInLazyRoute,
  InitializedMissingAccountInfoLazyRoute:
    InitializedMissingAccountInfoLazyRoute,
  InitializedOrderConfirmedLazyRoute: InitializedOrderConfirmedLazyRoute,
  InitializedOrgPasscodeLazyRoute: InitializedOrgPasscodeLazyRoute,
  InitializedPasscodeLazyRoute: InitializedPasscodeLazyRoute,
  InitializedVerifyAccountInfoLazyRoute: InitializedVerifyAccountInfoLazyRoute,
  InitializedAddressAddressLookupLazyRoute:
    InitializedAddressAddressLookupLazyRoute,
  InitializedAddressManualAddressFormLazyRoute:
    InitializedAddressManualAddressFormLazyRoute,
  InitializedAddressPoBoxErrorLazyRoute: InitializedAddressPoBoxErrorLazyRoute,
  InitializedAddressVerificationResultsLazyRoute:
    InitializedAddressVerificationResultsLazyRoute,
}

const InitializedRouteWithChildren = InitializedRoute._addFileChildren(
  InitializedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/$': typeof SplatRoute
  '': typeof InitializedRouteWithChildren
  '/cardInfo': typeof InitializedCardInfoRoute
  '/confirmOrder': typeof InitializedConfirmOrderRoute
  '/phoneVerification': typeof InitializedPhoneVerificationRoute
  '/terms': typeof InitializedTermsRouteWithChildren
  '/verificationSelection': typeof InitializedVerificationSelectionRoute
  '/verifyOTP': typeof InitializedVerifyOTPRoute
  '/confirmPasscode': typeof InitializedConfirmPasscodeLazyRoute
  '/dateOfBirth': typeof InitializedDateOfBirthLazyRoute
  '/directDepositAuthorization': typeof InitializedDirectDepositAuthorizationLazyRoute
  '/downloadApp': typeof InitializedDownloadAppLazyRoute
  '/linkAccount': typeof InitializedLinkAccountLazyRoute
  '/linkAccountSuccess': typeof InitializedLinkAccountSuccessLazyRoute
  '/linkedAccountSignIn': typeof InitializedLinkedAccountSignInLazyRoute
  '/missingAccountInfo': typeof InitializedMissingAccountInfoLazyRoute
  '/orderConfirmed': typeof InitializedOrderConfirmedLazyRoute
  '/orgPasscode': typeof InitializedOrgPasscodeLazyRoute
  '/passcode': typeof InitializedPasscodeLazyRoute
  '/verifyAccountInfo': typeof InitializedVerifyAccountInfoLazyRoute
  '/address/addressLookup': typeof InitializedAddressAddressLookupLazyRoute
  '/address/manualAddressForm': typeof InitializedAddressManualAddressFormLazyRoute
  '/address/poBoxError': typeof InitializedAddressPoBoxErrorLazyRoute
  '/address/verificationResults': typeof InitializedAddressVerificationResultsLazyRoute
  '/terms/': typeof InitializedTermsIndexLazyRoute
  '/terms/agreements/$step': typeof InitializedTermsAgreementsStepRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/$': typeof SplatRoute
  '': typeof InitializedRouteWithChildren
  '/cardInfo': typeof InitializedCardInfoRoute
  '/confirmOrder': typeof InitializedConfirmOrderRoute
  '/phoneVerification': typeof InitializedPhoneVerificationRoute
  '/verificationSelection': typeof InitializedVerificationSelectionRoute
  '/verifyOTP': typeof InitializedVerifyOTPRoute
  '/confirmPasscode': typeof InitializedConfirmPasscodeLazyRoute
  '/dateOfBirth': typeof InitializedDateOfBirthLazyRoute
  '/directDepositAuthorization': typeof InitializedDirectDepositAuthorizationLazyRoute
  '/downloadApp': typeof InitializedDownloadAppLazyRoute
  '/linkAccount': typeof InitializedLinkAccountLazyRoute
  '/linkAccountSuccess': typeof InitializedLinkAccountSuccessLazyRoute
  '/linkedAccountSignIn': typeof InitializedLinkedAccountSignInLazyRoute
  '/missingAccountInfo': typeof InitializedMissingAccountInfoLazyRoute
  '/orderConfirmed': typeof InitializedOrderConfirmedLazyRoute
  '/orgPasscode': typeof InitializedOrgPasscodeLazyRoute
  '/passcode': typeof InitializedPasscodeLazyRoute
  '/verifyAccountInfo': typeof InitializedVerifyAccountInfoLazyRoute
  '/address/addressLookup': typeof InitializedAddressAddressLookupLazyRoute
  '/address/manualAddressForm': typeof InitializedAddressManualAddressFormLazyRoute
  '/address/poBoxError': typeof InitializedAddressPoBoxErrorLazyRoute
  '/address/verificationResults': typeof InitializedAddressVerificationResultsLazyRoute
  '/terms': typeof InitializedTermsIndexLazyRoute
  '/terms/agreements/$step': typeof InitializedTermsAgreementsStepRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/$': typeof SplatRoute
  '/_initialized': typeof InitializedRouteWithChildren
  '/_initialized/cardInfo': typeof InitializedCardInfoRoute
  '/_initialized/confirmOrder': typeof InitializedConfirmOrderRoute
  '/_initialized/phoneVerification': typeof InitializedPhoneVerificationRoute
  '/_initialized/terms': typeof InitializedTermsRouteWithChildren
  '/_initialized/verificationSelection': typeof InitializedVerificationSelectionRoute
  '/_initialized/verifyOTP': typeof InitializedVerifyOTPRoute
  '/_initialized/confirmPasscode': typeof InitializedConfirmPasscodeLazyRoute
  '/_initialized/dateOfBirth': typeof InitializedDateOfBirthLazyRoute
  '/_initialized/directDepositAuthorization': typeof InitializedDirectDepositAuthorizationLazyRoute
  '/_initialized/downloadApp': typeof InitializedDownloadAppLazyRoute
  '/_initialized/linkAccount': typeof InitializedLinkAccountLazyRoute
  '/_initialized/linkAccountSuccess': typeof InitializedLinkAccountSuccessLazyRoute
  '/_initialized/linkedAccountSignIn': typeof InitializedLinkedAccountSignInLazyRoute
  '/_initialized/missingAccountInfo': typeof InitializedMissingAccountInfoLazyRoute
  '/_initialized/orderConfirmed': typeof InitializedOrderConfirmedLazyRoute
  '/_initialized/orgPasscode': typeof InitializedOrgPasscodeLazyRoute
  '/_initialized/passcode': typeof InitializedPasscodeLazyRoute
  '/_initialized/verifyAccountInfo': typeof InitializedVerifyAccountInfoLazyRoute
  '/_initialized/address/addressLookup': typeof InitializedAddressAddressLookupLazyRoute
  '/_initialized/address/manualAddressForm': typeof InitializedAddressManualAddressFormLazyRoute
  '/_initialized/address/poBoxError': typeof InitializedAddressPoBoxErrorLazyRoute
  '/_initialized/address/verificationResults': typeof InitializedAddressVerificationResultsLazyRoute
  '/_initialized/terms/': typeof InitializedTermsIndexLazyRoute
  '/_initialized/terms/agreements/$step': typeof InitializedTermsAgreementsStepRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/$'
    | ''
    | '/cardInfo'
    | '/confirmOrder'
    | '/phoneVerification'
    | '/terms'
    | '/verificationSelection'
    | '/verifyOTP'
    | '/confirmPasscode'
    | '/dateOfBirth'
    | '/directDepositAuthorization'
    | '/downloadApp'
    | '/linkAccount'
    | '/linkAccountSuccess'
    | '/linkedAccountSignIn'
    | '/missingAccountInfo'
    | '/orderConfirmed'
    | '/orgPasscode'
    | '/passcode'
    | '/verifyAccountInfo'
    | '/address/addressLookup'
    | '/address/manualAddressForm'
    | '/address/poBoxError'
    | '/address/verificationResults'
    | '/terms/'
    | '/terms/agreements/$step'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/$'
    | ''
    | '/cardInfo'
    | '/confirmOrder'
    | '/phoneVerification'
    | '/verificationSelection'
    | '/verifyOTP'
    | '/confirmPasscode'
    | '/dateOfBirth'
    | '/directDepositAuthorization'
    | '/downloadApp'
    | '/linkAccount'
    | '/linkAccountSuccess'
    | '/linkedAccountSignIn'
    | '/missingAccountInfo'
    | '/orderConfirmed'
    | '/orgPasscode'
    | '/passcode'
    | '/verifyAccountInfo'
    | '/address/addressLookup'
    | '/address/manualAddressForm'
    | '/address/poBoxError'
    | '/address/verificationResults'
    | '/terms'
    | '/terms/agreements/$step'
  id:
    | '__root__'
    | '/'
    | '/$'
    | '/_initialized'
    | '/_initialized/cardInfo'
    | '/_initialized/confirmOrder'
    | '/_initialized/phoneVerification'
    | '/_initialized/terms'
    | '/_initialized/verificationSelection'
    | '/_initialized/verifyOTP'
    | '/_initialized/confirmPasscode'
    | '/_initialized/dateOfBirth'
    | '/_initialized/directDepositAuthorization'
    | '/_initialized/downloadApp'
    | '/_initialized/linkAccount'
    | '/_initialized/linkAccountSuccess'
    | '/_initialized/linkedAccountSignIn'
    | '/_initialized/missingAccountInfo'
    | '/_initialized/orderConfirmed'
    | '/_initialized/orgPasscode'
    | '/_initialized/passcode'
    | '/_initialized/verifyAccountInfo'
    | '/_initialized/address/addressLookup'
    | '/_initialized/address/manualAddressForm'
    | '/_initialized/address/poBoxError'
    | '/_initialized/address/verificationResults'
    | '/_initialized/terms/'
    | '/_initialized/terms/agreements/$step'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  SplatRoute: typeof SplatRoute
  InitializedRoute: typeof InitializedRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  SplatRoute: SplatRoute,
  InitializedRoute: InitializedRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/$",
        "/_initialized"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/$": {
      "filePath": "$.tsx"
    },
    "/_initialized": {
      "filePath": "_initialized.tsx",
      "children": [
        "/_initialized/cardInfo",
        "/_initialized/confirmOrder",
        "/_initialized/phoneVerification",
        "/_initialized/terms",
        "/_initialized/verificationSelection",
        "/_initialized/verifyOTP",
        "/_initialized/confirmPasscode",
        "/_initialized/dateOfBirth",
        "/_initialized/directDepositAuthorization",
        "/_initialized/downloadApp",
        "/_initialized/linkAccount",
        "/_initialized/linkAccountSuccess",
        "/_initialized/linkedAccountSignIn",
        "/_initialized/missingAccountInfo",
        "/_initialized/orderConfirmed",
        "/_initialized/orgPasscode",
        "/_initialized/passcode",
        "/_initialized/verifyAccountInfo",
        "/_initialized/address/addressLookup",
        "/_initialized/address/manualAddressForm",
        "/_initialized/address/poBoxError",
        "/_initialized/address/verificationResults"
      ]
    },
    "/_initialized/cardInfo": {
      "filePath": "_initialized/cardInfo.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/confirmOrder": {
      "filePath": "_initialized/confirmOrder.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/phoneVerification": {
      "filePath": "_initialized/phoneVerification.ts",
      "parent": "/_initialized"
    },
    "/_initialized/terms": {
      "filePath": "_initialized/terms.tsx",
      "parent": "/_initialized",
      "children": [
        "/_initialized/terms/",
        "/_initialized/terms/agreements/$step"
      ]
    },
    "/_initialized/verificationSelection": {
      "filePath": "_initialized/verificationSelection.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/verifyOTP": {
      "filePath": "_initialized/verifyOTP.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/confirmPasscode": {
      "filePath": "_initialized/confirmPasscode.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/dateOfBirth": {
      "filePath": "_initialized/dateOfBirth.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/directDepositAuthorization": {
      "filePath": "_initialized/directDepositAuthorization.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/downloadApp": {
      "filePath": "_initialized/downloadApp.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/linkAccount": {
      "filePath": "_initialized/linkAccount.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/linkAccountSuccess": {
      "filePath": "_initialized/linkAccountSuccess.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/linkedAccountSignIn": {
      "filePath": "_initialized/linkedAccountSignIn.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/missingAccountInfo": {
      "filePath": "_initialized/missingAccountInfo.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/orderConfirmed": {
      "filePath": "_initialized/orderConfirmed.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/orgPasscode": {
      "filePath": "_initialized/orgPasscode.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/passcode": {
      "filePath": "_initialized/passcode.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/verifyAccountInfo": {
      "filePath": "_initialized/verifyAccountInfo.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/address/addressLookup": {
      "filePath": "_initialized/address/addressLookup.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/address/manualAddressForm": {
      "filePath": "_initialized/address/manualAddressForm.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/address/poBoxError": {
      "filePath": "_initialized/address/poBoxError.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/address/verificationResults": {
      "filePath": "_initialized/address/verificationResults.lazy.tsx",
      "parent": "/_initialized"
    },
    "/_initialized/terms/": {
      "filePath": "_initialized/terms/index.lazy.tsx",
      "parent": "/_initialized/terms"
    },
    "/_initialized/terms/agreements/$step": {
      "filePath": "_initialized/terms/agreements.$step.tsx",
      "parent": "/_initialized/terms"
    }
  }
}
ROUTE_MANIFEST_END */
